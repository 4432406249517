<template>
	<section class="col-12">
		<div>
			<div class="row">
				<div class="col-auto p-0">
					<h3>
						<b>{{ $t('global.title.supplier') }}</b>
					</h3>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-4 col-12">
				<supplier v-model="insurance.supplierId" :suppliers="suppliers" :disabled="disabled" />
			</div>
		</div>

		<template v-if="insurance.supplierId && $can('read', 'Supplier', 'InsurancePriceGroups')">
			<div class="row">
				<div class="col-auto p-0 mt-3">
					<h5>
						<b>{{ $t('global.title.supplierPriceGroup') }}</b>
					</h5>
				</div>
			</div>

			<div class="row">
				<div class="col-4">
					<InputDropdown v-model="insurance.supplierPriceGroup" :options="getSupplierInsurancePriceGroups" :disabled="disabled" />
				</div>
			</div>
		</template>

		<template v-if="insurance.supplierId">
			<div class="row">
				<div class="col-auto p-0 mt-3">
					<h5>
						<b>{{ $t('global.title.groupTemplate') }}</b>
					</h5>
				</div>
			</div>

			<div class="row">
				<div class="col-4">
					<supplier-policy
						v-model="insurance.supplierTemplateId"
						:groupTemplates="getGroupTemplates"
						:disabled="disabled"
						@supplierPolicyTemplateChanged="groupTemplatePicked"
					/>
				</div>
			</div>
		</template>

		<div class="row" v-if="insurance.supplierId">
			<div class="col-auto p-0 mt-3">
				<h5>
					<b>Forsikring startdato obligatorisk (checkbox)</b>
				</h5>
			</div>
		</div>

		<div class="row">
			<div class="col-4">
				<InputCheck v-if="insurance.supplierId" v-model="insurance.healthInsuranceIsMandatory" :disabled="disabled" />
			</div>
		</div>


		<div class="row">
			<div class="col-12">
				<hr />
			</div>
		</div>
		<div class="row">
			<insurance v-if="insurance.supplierId" v-model="insurance" :disabled="disabled" :insuranceIndex="insuranceIndex" :suppliers="suppliers" />
		</div>
	</section>
</template>

<script>
import Insurance from '@/modules/global/components/Insurance.vue'
import Swal from 'sweetalert2'
import Supplier from '@/modules/global/components/Supplier'
import SupplierPolicy from '@/modules/global/components/SupplierPolicyTemplate'
import InputDropdown from '@/modules/global/atomComponents/InputDropdown'
import InputCheck from '@/modules/global/atomComponents/InputCheck'

export default {
	name: 'CompanyInsurance',
	components: {
		'insurance': Insurance,
		'supplier': Supplier,
		'supplier-policy': SupplierPolicy,
		InputDropdown,
		InputCheck,
	},
	props: {
		value: Object,
		insuranceIndex: Number,
		suppliers: Array,
		disabled: Boolean,
	},
	data() {
		return {}
	},
	methods: {
		groupTemplatePicked(index) {
			Swal.fire({
				title: 'Overskrive forsikringsaftaler',
				text: 'Vil du overskrive forsikringsaftaler? - Du skal dog stadig gemme ændringer.',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ja overskriv!',
			}).then((result) => {
				if (result.isConfirmed) {
					this.$emit('groupSupplierTemplateChanged', {
						index: index,
						supplierId: this.insurance.supplierId,
						templateId: this.insurance.supplierTemplateId,
					})
					Swal.fire('Overskrevet!', '..', 'success')
				}
			})
		},
	},
	computed: {
		getGroupTemplates() {
			return this.suppliers.find((item) => {
				return item.id == this.value.supplierId
			}).groupTemplates
		},
		getSupplierInsurancePriceGroups() {
			return this.suppliers.find((item) => {
				return item.id == this.value.supplierId
			}).insurancePriceGroups
		},
		insurance: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			},
		},
	},
}
</script>

<style></style>
