<template>
	<section class="container">
		<div class="row justify-content-end">
			<div class="col-auto">
				<BaseActionButton type="button" v-on:on-click="setEditable" v-if="$can('update', 'Company', 'Insurance')">
					<font-awesome-icon :icon="['far', 'edit']" class="icon" />
				</BaseActionButton>
			</div>
		</div>

		<div class="row mt-5">
			<div class="col-auto p-0">
				<h3><b>Forsikringsaftale</b> {{ companyInsurance.insurance.name }}</h3>
			</div>
		</div>
		<b-form @submit.prevent="onSubmit">
			<div class="row">
				<div class="col-12">
					<hr />
				</div>
			</div>

			<div class="row">
				<div class="col-auto">
					<div class="row">
						<div class="col-auto order-md-2">
							<BaseActionButton class="btn-add" type="button" @click.native="addGroup" :disabled="disabled"> Tilføj gruppe </BaseActionButton>
						</div>
						<div class="col-auto order-md-3">
							<BaseActionButton class="btn-update" type="button" @click.native="removeGroup" :disabled="disabled"> Fjern gruppe </BaseActionButton>
						</div>
						<div class="col-12 order-md-first"><b>Grupper</b></div>
						<template v-for="(groupId, index) in companyInsurance.groups">
							<div class="col-12" :key="index">
								<b> {{ index + 1 }} - {{ getGroupName(groupId) }}</b>
							</div>
						</template>
					</div>
				</div>
				
			</div>

			<div class="row">
				<div class="col-auto p-0">
					<h3>
						<b>Navn</b>
					</h3>
				</div>
			</div>
			<div class="row">
				<div class="col-4">
					<b-form-input v-model="companyInsurance.insurance.name" :disabled="disabled" />
				</div>
			</div>
			<div class="row" v-if="insurance">
				<company-insurance
					v-model="companyInsurance.insurance"
					:suppliers="suppliers"
					:disabled="disabled"
					@groupSupplierTemplateChanged="loadSupplierTemplateInsurance"
				/>
			</div>

			<div class="row">
				<div class="col-3">
					<BaseActionButton
						class="w-100 btn-update"
						v-if="$can('update', 'Company', 'Insurances')"
						v-bind:clicked="clicked"
						v-bind:disabled="disabled || clicked"
						type="submit"
					>
						{{ $t('global.update') }}
					</BaseActionButton>
				</div>
			</div>

			<div class="col-auto ml-auto d-none d-md-block text-right">
				<BaseActionButton class="btn-delete" type="button" @click.native="deleteInsurance" :disabled="disabled">
						Slet forsikringsaftalen
					</BaseActionButton>
				</div>

			<div class="row mt-5 mb-5">
				<div class="col-auto"></div>
			</div>
		</b-form>
	</section>
</template>
<script>
import CompanyInsurance from '@/modules/general/company/components/CompanyInsurance'
import CompanyGroupHandler from '@/modules/general/company/components/CompanyGroupHandler'
import { superModel } from '@/models/superModel'

export default {
	name: 'CompanyInsurance',
	components: {
		'company-insurance': CompanyInsurance,
		'group-dropdown': CompanyGroupHandler,
	},

	async mounted() {
		this.$parent.$emit('updateLoader')
	},
	data() {
		return {
			disabled: true,
			clicked: false,
		}
	},
	computed: {
		groups() {
			return this.$store.getters['companyVuex/groups']
		},
		id() {
			return this.$route.params.companyId
		},
		companyId() {
			return this.$route.params.companyId
		},
		company() {
			return this.$store.getters['companyVuex/company']
		},
		suppliers() {
			return this.$store.getters['supplier/suppliers']
		},
		insuranceId() {
			return this.$route.params.insuranceId
		},
		companyInsurance() {
			return this.$store.getters['companyVuex/insurance'](this.insuranceId)
		},
		insurance() {
			return this.companyInsurance?.insurance ?? {}
		},
	},
	methods: {
		setEditable() {
			this.disabled = !this.disabled
		},
		async addGroup() {
			var inputOptions = this.groups
				.filter((group) => {
					return !this.companyInsurance.groups.includes(group.id)
				})
				.map((group) => {
					return group.name
				})
			const { value: groupIndex } = await this.$swal.fire({
				title: 'Vælg en gruppe',
				input: 'radio',
				inputOptions: inputOptions,
				inputPlaceholder: 'Vælg en gruppe',
				showCancelButton: true,
				inputValidator: (value) => {
					return new Promise((resolve) => {
						if (value !== '') {
							resolve()
						} else {
							resolve('Du skal vælge en gruppe')
						}
					})
				},
			})
			if (groupIndex) {
				var groupName = inputOptions[groupIndex]
				this.companyInsurance.groups.push(this.groups.find((group) => group.name == groupName).id)
			}
		},
		async deleteInsurance() {
			await this.$swal
				.fire({
					title: 'Er du sikker på at du vil slette denne forsikringsaftale?',
					text: 'Denne handling kan ikke fortrydes, og alle medarbejdere der er tilknyttet til denne aftale via gruppen vil miste deres forsikringsaftale.',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Ja slet!',
					showLoaderOnConfirm: true,
					preConfirm: async () => {
						try {
							var companyId = this.companyId
							var insuranceId = this.insuranceId
							var result = await this.$store.dispatch('companyVuex/deleteInsurance', { companyId, insuranceId })
							return result
						} catch (error) {
							console.log(error)
							this.$swal.showValidationMessage(`Request failed: ${error.message}`)
						}
					},
				})
				.then((result) => {
					if (result.isConfirmed) {
						this.toast('Success', 'En forsikringsaftale er blevet slettet', true)
						this.clicked = false
						this.$router.go(-1)
					}
				})
		},
		async removeGroup() {
			var inputOptions = this.groups
				.filter((group) => {
					return this.companyInsurance.groups.includes(group.id)
				})
				.map((group) => {
					return group?.name ?? ""
				})
			const { value: groupIndex } = await this.$swal.fire({
				title: 'Vælg en gruppe',
				input: 'radio',
				inputOptions: inputOptions,
				inputPlaceholder: 'Vælg en gruppe',
				showCancelButton: true,
				inputValidator: (value) => {
					return new Promise((resolve) => {
						if (value !== '') {
							resolve()
						} else {
							resolve('Du skal vælge en gruppe')
						}
					})
				},
			})
			if (groupIndex) {
				var groupName = inputOptions[groupIndex]
				this.companyInsurance.groups.splice(this.companyInsurance.groups.indexOf(this.groups.find((group) => group.name == groupName).id), 1)
			}
		},
		getGroupName(groupId) {
			return this.company.groups.find((group) => group.id == groupId)?.name
		},
		async loadSupplierTemplateInsurance({ index, supplierId, templateId }) {
			var template = Object.assign({}, this.suppliers.find((supplier) => supplier.id === supplierId).groupTemplates[index])
			var templateInsurance = superModel('insurance', template.insurance)
			templateInsurance.supplierId = supplierId
			templateInsurance.supplierTemplateId = templateId
			templateInsurance.name = this.insurance.name
			templateInsurance.clearComments()
			await this.$store.commit('companyVuex/setInsurance', {
				id: this.insuranceId,
				insurance: templateInsurance,
			})
		},

		async onSubmit() {
			this.clicked = true
			var data = this.companyInsurance
			var companyId = this.companyId
			var insuranceId = this.insuranceId
			try {
				var result = await this.$store.dispatch('companyVuex/updateCompanyInsurance', {
					data,
					companyId,
					insuranceId,
				})
				this.clicked = false
				this.toast('Success', 'Forsikringsaftalen er nu opdateret', true)
			} catch (error) {
				this.clicked = false
				this.toast(error.response.data.code, error.response.data.message, false)
			}
		},
	},
}
</script>
<style lang="scss">
@import '@/styles/pages/companyAdd.scss';
</style>
